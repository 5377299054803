<template>
    <base-page>
        <titlu-pagina Titlu="Programari" @on_add_clicked="show_dialog()"  />

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix" style="text-align:center" >
                <strong> Filtre </strong>
            </div>
             <div class="filtre" style="text-align:center; ">
                
                <el-row :gutter="20">
                    <el-form :inline="true" @submit.prevent.native='refresh_info()' >
                            <el-form-item label='Mecanic' >
                                <el-select class='full-width' v-model='Filters.IdMecanic' filterable >
                                    <el-option label='Toti' value='-1'></el-option>
                                    <el-option v-for='item in Info.mecanici' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        <el-button  @click='reset'> Reset </el-button>
                    </el-form>
                </el-row>
               
            </div>
        </el-card>

        <el-card style='margin:5px 0px 5px 0px; padding:50px' v-loading="loadingCalendar">            
            <Calendar :Programari.sync="Results" @programareNoua='programareNoua' @editProgramare='editProgramare' @mutaProgramare='mutaProgramare' />
        </el-card>

        <el-dialog title="Programare" :visible.sync="showPopup" width="80%" >
            <el-form label-position="top" :inline="false" label-width="100%" :model='selectedObject' ref='my-form' v-loading="loading">
                <el-row :gutter="15" >  
                    <el-col :span="8">
                        <el-form-item label='Masina' prop="IdMasina">
                            <div class="input-group">    
                                <SelectMasina 
                                    class="full-width input-group-input" 
                                    v-model="numarMasina" 
                                    :NumarMasina="numarMasina" 
                                    :IdMasina.sync="selectedObject.IdMasina" 
                                    @selectie="select_masina" />
                                <div class="input-group-append">
                                    <el-button  plain type="success" icon="el-icon-plus" @click="adaugaMasina"></el-button>    
                                </div>
                            </div>
                        </el-form-item>
                    </el-col> 
                    <el-col :span='8'>
                        <el-form-item label='Mecanic' prop="IdMecanic">
                        <el-select v-model='selectedObject.IdMecanic' class='full-width' filterable>
                            <el-option label='Fara' value='-1'></el-option>        
                            <el-option v-for='item in Info.mecanici' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span='8'>
                        <el-form-item label='Data start' prop="DataStart">
                            <el-date-picker class='full-width' v-model='selectedObject.DataStart' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="24">
                        <h6 style="margin-bottom:20px;">Produse</h6>
                        <lista-grupe ref="lista"/>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer" >     
                <el-button v-if="mode=='edit'" type="danger" @click="delete_item()" > Sterge  </el-button>                   
                <el-button @click="showPopup=false" > Renunta  </el-button>                
                <el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
            </span>
        </el-dialog>

<!-- <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" /> -->
        <Programari-dialog ref='dlg' @save="refresh_info()" />
        <Masini-dialog ref='masini_dlg'  @save="salvare_masina"/>

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Programari_dialog from '@/pages/programari/Programari_dialog.vue';
import SelectMasina from '@/components/SelectMasina'
import Masini_dialog from '@/pages/masini/Masini_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import Calendar from '@/components/Calendar';
import ListaGrupe from '@/components/grupe_produse/ListaGrupe.vue';
import moment from 'moment'
 
export default {
    name: "programari",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Programari-dialog': Programari_dialog,
        'titlu-pagina': TitluPagina,
        'lista-grupe': ListaGrupe,
        Calendar,
        SelectMasina,
        'Masini-dialog': Masini_dialog,

    },
    watch: {
        
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                mecanici: [], oferte: [], masini: []},
            Filters: {
                IdMecanic: '-1' , DataStart: '' , IdOferta: '-1' , IdMasina: '-1' , Status: '-1' , Durata: '' ,},
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            programari:[],
            selectedObject: {Id:'-1', IdMecanic: '-1' , DataStart: ''},
            showPopup: false,
            grupeProduse:[],
            Utilizatori:[],
            mode: 'add',
            numarMasina:'',
            loading:false,
            loadingCalendar:false,
            dataCalendar:''
        }
    },
    methods: {
        setCalendar(){
            this.Results.forEach((r,index) => {
                r.id    = r.Id
                r.title = r.Numar + ' - ' + r.Mecanic
                r.date  = moment(r.DataStart).format('YYYY-MM-DD HH:mm')
            })
        },

        handleDateClick: function(arg) {
            alert('date click! ' + arg.dateStr)
        },

        handleWeekendsToggle() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
        },
        handleDateSelect(selectInfo) {
            let title = prompt('Please enter a new title for your event')
            let calendarApi = selectInfo.view.calendar
            calendarApi.unselect() // clear date selection
            if (title) {
                calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
                })
            }
        },
        handleEventClick(clickInfo) {
            if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
                clickInfo.event.remove()
            }
        },
        handleEvents(events) {
            this.currentEvents = events
        },

        async get_info(){
            
            if (!this.$has_right('vizualizare-programari')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("programari/get_info_calendar" );
            this.Info.mecanici  = response.utilizatori;           
            this.Info.masini    = response.masini;           
            this.refresh_info();
        },

        async refresh_info(){
            this.loadingCalendar = true
            var response        = await this.post("programari/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('programari');

            this.setCalendar()
            this.loadingCalendar = false
        },
        reset(){
            this.Filters = { IdMecanic: '-1' , DataStart: '', IdMasina: '-1' };                
            this.refresh_info();
        },

        async delete_item( ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("programari/delete_item", { id: this.selectedObject.Id } );
                    this.refresh_info(); 
                    this.showPopup = false
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        async show_dialog(id){                      
            this.loading                    = false
            this.numarMasina                = ''
            this.selectedObject.Id          = '-1'
            this.selectedObject.IdMecanic   = this.Filters.IdMecanic
            this.showPopup                  = true
            await (this.$nextTick())
            this.mode                       = 'add'
            this.grupeProduse               = []
            this.$refs.lista.emptyProduse();

            if(id != -1){
                this.get_grupe_produse(id)
                this.mode = 'edit'
            }
        },

        async select_masina(item){ 
            this.numarMasina = item.Numar   
        },
        async salvare_masina(){
            this.get_info();
            var response                    = await this.post('fisa_intrare/get_last_masina', {})
            this.selectedObject.IdMasina    = response.LastCarId            
            this.numarMasina                = response.NumarMasina
        },

        async get_grupe_produse(id){     
            var result          = await this.post("programari/get_produse_calendar", { id: id } );
            this.selectedObject = result.Programare;
            this.grupeProduse   = result.Grupe;
            this.numarMasina    = result.Programare.NumarMasina
            this.$refs.lista.setGrupeProduse(this.grupeProduse)
        },

        save: async function() {  
            this.grupeProduse = this.$refs.lista.getGrupeProduse();                                       
            this.$refs['my-form'].validate( async(valid) => {
                if (valid) {                                                                     
                        this.loading = true
                        await this.post("programari/save_programari_calendar", { mode: this.mode, object: this.selectedObject, grupe_produse: this.grupeProduse, } );
                        this.$message({type: 'success', message: 'Programarea a fost inregistrata'});
                        this.showPopup = false
                        this.refresh_info()
                        this.loading = false
                }
            });
        },

        programareNoua(data) {
            this.selectedObject.DataStart = moment(data).format("YYYY-MM-DD 12:00:00")
            this.show_dialog(-1)          
        },
        editProgramare(obj){
            var item = JSON.parse(obj)
            this.show_dialog(item.id)
        },
        mutaProgramare(data){
            var item = JSON.parse(data)
            console.log(item.id, item.start);    
            this.modifica_data_programare(item.id, item.start)            
        },
        async modifica_data_programare(id_programare, data_noua){
            data_noua = moment(data_noua).format("YYYY-MM-DD 12:00:00")
            await this.post("programari/modifica_data_programare", { id: id_programare, data: data_noua } );
            this.$message({type: 'success', message: 'Programarea a fost inregistrata'});
            this.refresh_info()
        },
        adaugaMasina(){
            this.$refs.masini_dlg.show_me();
        },
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();        
    }
};
</script>

<style lang="less" >

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }
    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }
    .full-width {
        width: 100%;
    }

    .fc-daygrid-event-harness{
        a:hover{ // TODO: facultativ
            background-color: #f0b737;
        }
        .fc-daygrid-event-dot {
            display: none;
        }
        .fc-event-time{
            margin-left: 5px;
        }
    }
    

</style>
